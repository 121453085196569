.wodebaodan{
    .baoxians{padding:5px 5px 1.2rem 5px;background: #fff;height: 100%;}
    .baoxian {
        // margin: 5px;
        border: 1px solid #ff7f7f;
        border-radius: 10px;
        overflow: hidden;
        min-height: 35rem;
        // font-size: .38rem;
        background: #fff;
        //////
    
        *::before{
            background:none !important;
        }    
        *::after{
            // background:none !important;
            box-sizing: border-box;
            padding:0 .2rem;
            background: #dfdfdf !important;
        }
        .am-list-item{
            padding-right:15px;
        }
        // .bx-btn::before{
            // background:none !important
        // }
    
        .headContent {
            display: flex;
            justify-content: center;
            font-size: 1.5rem;
            color: #fff;
            height: 2rem;
            background: linear-gradient(to right, #f00c0c, #ebba92);
    
            span {
                align-self: center;
                height: 50px;
                width: auto;
                line-height: 50px;
                font-size:.47rem;
                font-weight: 500;
            }
    
            .logo {
                align-self: center;
                width:1.69rem;
                height: auto;
                margin-right:.55rem;
            }
        }
        // ::-webkit-input-placeholder{font-size: .38rem;color:#c0c0c0} 
        // :-moz-placeholder{font-size: .38rem;color:#c0c0c0}           
        // ::-moz-placeholder{font-size: .38rem;color:#c0c0c0}          
        // :-ms-input-placeholder{font-size: .38rem;color:#c0c0c0}    
        .baoxiantitle{
            // border:1px solid red !important ;
            // padding:20px 0 15px 0;
            padding:1.1rem 0 .46rem 0;
            margin:0 .58rem .47rem;
            border-bottom:1px solid #dfdfdf;
            text-align: left;
            font-size:17px;
            font-weight: 600;
            color:#000;
            font-weight: 600;
            // padding-left: 15px;;
        }
        .bx-xiangmuxinxi{
            // border:1px solid red ;
            text-align: left;
        }
        .am-accordion-header{
            background: url('../../images/bx-ziyuan.png') no-repeat .45rem center;
            background-size: .47rem .68rem;
            text-align: left;
            padding-left:1.25rem !important;
            margin-bottom: .55rem;
            color: #f15a24 !important;
            font-size: .42rem;
        }
        // .my-accordion::before{background: none !important;}
        .am-accordion-header::after{background: none !important;}
        .am-list-extra{
            color:#f15a24 !important
        }
        .my-accordion1::before{
            background: #dfdfdf !important;
            margin:0 .35rem;
            width:92% !important
        }
        .my-accordion2::before{
            background: none !important;
        }   
         .my-accordion2 .am-accordion-item{
            margin-top:.85rem;
        }
        .am-list-body::after{
            background: #dfdfdf !important;
            width: 92% !important;
            margin:0 .35rem;
        }
    
        // .am-list-content{font-size:.38rem !important;}
        .am-list-line{padding:.35rem 0}
        .am-list-arrow{display: none !important}
        .arrow{right:.8rem !important}
        // .am-input-label{font-size:.38rem !important} 
        .bx-jihuozhuantai{
            text-align: left;
            padding:.5rem 0 1.2rem .48rem;
            // font-size:.38rem;
            font-size: 16px;
            color:#c0c0c0
        }
        .bx-jihuozhuantai i{
            margin-right:1.1rem;
            font-weight: 600;
            color:#000
    
        }
        .flexcenter {
            // display: none;
            position: fixed;
            left: 0;
            right: 0;
            top: 4.3rem;
            z-index: 999999999999;
            margin: 0 auto;
        }
        .am-list-item .am-list-line .am-list-extra{
            // font-size:.38rem;
        }
        .am-list-item .am-input-control input{
            // font-size: .38rem;
            color:#f15a24 !important
            
        }
        .bx-color1{
            color:#f15a24
        }
        .bx-xingming input{
            text-align: right !important;
        }
        .cbao{
            font-weight: 600;
            padding:35px 0 25px;
            background: #fff;
            font-size:16px;
            color:#000
        }
        .bx-btn{
            border:none !important ;
            width: 3.2rem;
            height:1.12rem;
            line-height: 1.12rem;
            border-radius:.55rem;
            margin:.65rem auto 1rem ;
            color:#fff;
            background: linear-gradient(to right, #f00c0c, #ebba92);
        }
    
    }
    // 用户不存在弹框
    .headContent {
        display: flex;
        justify-content: center;
        font-size: 1.5rem;
        color: #fff;
        height: 2rem;
        background: linear-gradient(to right, #f00c0c, #ebba92);

        span {
            align-self: center;
            height: 50px;
            width: auto;
            line-height: 50px;
            font-size: 21px;
            font-weight: 500;
        }

        .logo {
            align-self: center;
            width: 1.69rem;
            height: auto;
            margin-right: .55rem;
        }
    }
    .bindButton {
        color     : #fff;
        font-size : 14px;
        margin    : 0 5px 5px 5px;
        background: linear-gradient(to right, #f00c0c, #ebba92);
    }


}