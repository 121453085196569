*{
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, nsub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: normal;
    vertical-align: baseline;
    -moz-user-select:   none;  /*禁止页面选中文字*/
    /*cursor:pointer*/
}
ul,ol,li{list-style:none;margin-top: 0;
    margin-bottom: 0;}
i, em { font-style: normal; }
a{text-decoration:none;}
.clear-fix:after{content: '';display: table;clear: both;height:0;line-height:0;visibility: hidden}
.clear-fix{*zoom:1;}
input, textarea, button, a {
    outline: 0;
}
textarea {  -webkit-appearance: none;}
input[type=button], input[type=submit], input[type=file], button { cursor: pointer; -webkit-appearance: none; }
button, select {
    text-transform: none;
}
input {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
button[disabled], html input[disabled] {
    cursor: default;
}
input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}
input[type=search] {
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    border-color: grey;
}
img { height: auto; width: auto\9;vertical-align:middle; }
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
    display: block;
}
/*滚动条样式*/
::-webkit-scrollbar {/*滚动条整体样式*/
    width: 12px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
}
::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: #c1c1c1;
}
::-webkit-scrollbar-track {/*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: #f1f1f1;
}

body {
    margin: 0;
    color: rgba(0, 0, 0, 0.65);
    font-weight:400;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-variant: tabular-nums;
    line-height: 1.5;
    background-color: #fff;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    width: 100%;
    height: 100%;
}
#root,.App{
    width: 100%;
    height: 100%;
}
html {
    width: 100%;
    height: 100%;
    font-size: 9.2592592592592vw; /* 100vw / 1080 * 100 */
    /*font-size: 12.5vw; !* 100vw / 1920 * 100 *!*/
    -webkit-text-size-adjust: none;/*禁用Webkit内核浏览器的文字大小调整功能、禁用ios点击元素高亮*/
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
a:hover{
    text-decoration: none;
}
a{color:#616161}
*, *::before, *::after {
    box-sizing: border-box;
}
::selection {
    /*color: #fff;*/
     background: #1890ff;
} 
.logo {
    align-self: center;
    width: 1.9rem !important;
    height:auto !important
}