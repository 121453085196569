.MyProduction {
    margin: 5px;
    border: 1px solid rgba($color: red, $alpha: 0.8);
    border-radius: 10px;
    overflow: hidden;
    min-height: 11.2rem;

    .flexcenter {
        margin-top: 3.8rem;
    }

    .headContent {
        display: flex;
        justify-content: center;
        font-size: .48rem;
        color: #fff;
        height: 75px;
        background: linear-gradient(to right, #f00c0c, #ebba92);

        span {
            align-self: center;
            height: 50px;
            width: 150px;
            line-height: 50px;
        }

        .logo {
            align-self: center;
            width: 70px;
            height: 50px;
        }
    }

    .am-flexbox {

        
        a {
            font-size: .25rem;
            margin-right: 5px;
            float: right;
        }

        .am-flexbox-item {
            flex: 1 1 auto !important;
        }
    }
    .greyFlex {
        background-color: rgb(219, 213, 213);
    }


    .am-radio-wrapper {
        font-size: .25rem;
        line-height: 18px;
    }

    .am-radio {
        border: 1px solid skyblue;
        border-radius: 50%;
    }

    .bindButton {
        color: #fff;
        width:3.2rem;
        height:1.12rem;
        line-height: 1.12rem;
        border-radius: .55rem;
        margin: .5rem auto !important;
        font-size: .38rem;
        // margin: .5rem 5px 5px 5px;
        background: linear-gradient(to right, #f00c0c, #ebba92);
    }

    .productlist {
        background: linear-gradient(to right, #f11616, #ecc09b);

        .am-list-header {
            color: white;
            font-weight: bold;
            font-size: .32rem;
            text-align: left;
        }

        .am-list-item,
        .am-whitespace {
            background-color: rgb(219, 213, 213);
        }
    }

}