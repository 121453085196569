.AfterSales {
    margin       : 5px;
    border       : 1px solid rgba($color: red, $alpha: 0.8);
    border-radius: 10px;
    overflow     : hidden;
    min-height: 11.2rem;
    
    .flexcenter{
        margin-top: 3.8rem;
    }

    .headContent {
        display        : flex;
        justify-content: center;
        font-size      : .48rem;
        color          : #fff;
        height         : 75px;
        background     : linear-gradient(to right, #f00c0c, #ebba92);

        span {
            align-self : center;
            height     : 50px;
            width     : 150px;
            line-height: 50px;
        }

        .logo {
            align-self: center;
            width     : 70px;
            height    : 50px;
        }
    }

    .am-flexbox {

        a {
            font-size   : .25rem;
            margin-right: 5px;
            float       : right;
        }

        .am-flexbox-item {
            flex: 1 1 auto !important;
        }
    }

    .am-radio-wrapper {
        font-size  : .25rem;
        line-height: 18px;
    }

    .am-radio {
        border : 1px solid skyblue;
        border-radius: 50%;
    }

    .bindButton {
        color     : #fff;
        font-size : 16px;
        width:150px;
        border-radius: 25px;
        margin: 5px auto;
        background: linear-gradient(to right, #f00c0c, #ebba92);
    }

}