.baoxians {
    padding: 5px 5px 1.2rem 5px;
    background: #fff;
    height: 100%;
}

.baoxianshouhuzhe {
    // margin: 5px;
    border: 1px solid rgba($color: red, $alpha: 0.8);
    border-radius: 10px;
    overflow: hidden;
    // min-height: 35rem;
    // font-size: .38rem;

    //////
    .flexcenter {
        margin-top: 12rem;
        z-index: 9999;
    }

    .headContent {
        display: flex;
        justify-content: center;
        font-size: 1.5rem;
        color: #fff;
        height: 2rem;
        background: linear-gradient(to right, #f00c0c, #ebba92);

        span {
            align-self: center;
            height: 50px;
            width: auto;
            line-height: 50px;
            font-size: .47rem;
            font-weight: 500;
        }

        .logo {
            align-self: center;
            width: 1.69rem;
            height: auto;
            margin-right: .55rem;
        }
    }

    .baoxianContent {
        overflow: auto;
        background: #fff;
    }

    .bx-xiangmuxinxi {
        // border:1px solid red ;
        text-align: left;
    }

    .am-accordion-header {
        background: url('../../images/bx-ziyuan.png') no-repeat 10px center;
        background-size: .47rem .68rem;
        text-align: left;
        padding-left: 35px !important;
        color: #f15a24 !important;
    }

    // .am-list-item .am-list-line .am-list-content {
    // font-size: .38rem;
    // }


    .bx-jihuozhuantai i {
        margin-right: 50px;
    }

    .bx-color1 {
        color: #f15a24
    }

    .cbao {
        font-weight: 600;
        padding: 35px 0 25px;
        background: #fff;
    }

    .bx-btn {
        border: none !important;
        width: 3.2rem;
        height: 1.12rem;
        line-height: 1.12rem;
        border-radius: 30px;
        margin: 1.64rem auto;
        color: #fff;
        font-size: .42rem;
        background: linear-gradient(to right, #f00c0c, #ebba92);
    }

    .bxfuxuankuang {
        background: #fff;
        display: -webkit-flex;
        display: flex;
        // justify-content: center;
        align-items: center;
    }




    // .looo .am-list-line {
    //     display: inline-block;
    //     position: relative;
    //     // flex:0.5;
    //     // justify-content: left;
    //     // align-items: left;
    // }

    // .looo .am-list-arrow {
    //     position: absolute;
    //     right: 1.2rem;
    //     top: .25rem;
    // }

    .bxfuxuankuang .am-list-header {
        text-align: left;
        padding: 0 0 0 15px;
        // font-size: .38rem;
        color: #000;
        width: auto !important;

    }

    // ::-webkit-input-placeholder {
    //     // font-size: .38rem;
    //     color: #c0c0c0
    // }

    // :-moz-placeholder {
    //     // font-size: .38rem;
    //     color: #c0c0c0
    // }

    // ::-moz-placeholder {
    //     // font-size: .38rem;
    //     color: #c0c0c0
    // }

    // :-ms-input-placeholder {
    //     // font-size: .38rem;
    //     color: #c0c0c0
    // }


    .bxfuxuankuang .am-list-body {
        // border:1px solid red ;
        display: -webkit-flex;
        display: flex;
        font-size: 16px;
        justify-content: right;
        align-items: center;

    }

    .bxfuxuankuang-title {
        background: #fff;
    }

    .bxfuxuankuang-title div {
        background: url('../../images/bx-ziyuan.png') no-repeat .45rem .82rem;
        ;
        background-size: .47rem .68rem;
        color: #f15a24;
        font-size: .42rem;
        text-align: left;
        border: none;
        padding: .9rem 0 .6rem 1.25rem;
    }

    // .am-list-item .am-list-item-middle .am-list-line {
    // font-size: .38rem !important;
    // }

    *,
    *:before,
    *:after {
        -webkit-tap-highlight-color: #fff;
    }

    *::after {
        background-color: #fff !important
    }

    .am-checkbox-inner::after {
        background-color: #108ee9 !important
    }


    .am-list-line {
        border-bottom: .02rem solid #ccc !important;
        margin-right: .25rem !important
    }
    .am-list-body .am-list-line{
        border-bottom: none !important;
        margin-right: .25rem !important
    }

    .am-list-body::before {
        background-color: #fff !important
    }

    .bxfuxuankuang .am-list-item .am-list-thumb:first-child {
        margin-right: 2px;
    }

    .bxfuxuankuang .am-list-item {
        padding-left: 0;
        min-height: .44rem !important
    }

    .baoxianContent .am-list-item {
        margin: .55rem 0 !important;
    }

    .bxfuxuankuang .am-list-line {
        padding-left: 15px;
        padding-right: 0
    }

    // .am-list-line::after {
    //     background-color: #fff !important;
    // }
    .flexcenter {
        // display: none;
        position: fixed;
        left: 0;
        right: 0;
        top: 2.8rem;
        margin: 0 auto;
    }


    .bxfuxuankuang .am-checkbox-inner {
        border-radius: 3px;
    }

    .am-list .am-list-item.am-checkbox-item .am-list-thumb .am-checkbox-inner {
        left: .35rem;
        top: .25rem;
    }

    // .am-radio-inner {
    //     left: .35rem !important;
    //     top: .16rem !important;
    //     position: absolute;
    //     right: 0;
    //     width: 21px;
    //     height: 21px;
    //     border: 1px solid #ccc;
    //     border-radius: 3px !important;
    //     transform: rotate(0deg);
    //     box-sizing: border-box;

    // }








}