.BindProduction {
    margin: 5px;
    border: 1px solid rgba($color: red, $alpha: 0.8);
    border-radius: 10px;
    overflow: hidden;
    min-height: 18rem;
    .flexcenter {
        margin-top: 2rem;
    }

    .headContent {
        display: flex;
        justify-content: center;
        font-size: .48rem;
        color: #fff;
        height: 75px;
        background: linear-gradient(to right, #f00c0c, #ebba92);

        span {
            align-self: center;
            height: 50px;
            width: 150px;
            line-height: 50px;
        }

        .logo {
            align-self: center;
            width: 70px;
            height: 50px;
        }
    }

    .am-flexbox {

        a {
            font-size: .25rem;
            // margin-right: 5px;
            float: right;
            color: rgb(43, 46, 211);
        }

        a:visited {
            color: rgb(43, 46, 211);
        }

        .am-radio-inner:after {
            right: 3px;
        }

        .am-flexbox-item {
            flex: 1 1 auto !important;
            .am-checkbox-wrapper {
                // padding-left: 10px;
                display: inline-block;
                padding: 10px 0 10px 10px;
            }


            .am-radio-wrapper {
                width: 1.96rem;
                font-size: .25rem;
                line-height: 18px;
                float: right;

                .am-radio {
                    position: relative;
                    top: -2px;
                    margin-right: 3px;
                }
            }

            .am-checkbox-inner {
                border-radius: 3px !important;
                margin-right: 3px;
                margin-left: 5px;
                margin-top: -1px;
            }

            .am-radio {
                border: 1px solid skyblue;
                border-radius: 50%;

                .am-radio-input {
                    width: 21px;
                    height: 21px;
                    border-radius: 0 !important;
                }
            }

            a {
                font-size: 12px;
                float: right;
            }

            .am-button-ghost::before {
                border: none;
            }
        }
    }

    .bindButtonDisabled {
        color: #fff !important;
        font-size: 14px;
        margin: 0 5px 5px 5px;
        background: rgb(141, 133, 133);
    }

    .bindButton {
        color: #fff;
        font-size: 14px;
        // width:3.2rem;
        // height:1.12rem;
        line-height: 1.12rem;
        border-radius: .55rem !important;
        margin: 0 auto;
        font-size: .38rem;
        // margin: 0 5px 5px 5px;
        background: linear-gradient(to right, #f00c0c, #ebba92);
    }

}