.guardianhome {
    .guardianbg {
        width:10.8rem;
        // height: 39.75rem;
    }
    position: relative;
    .guardiananniu {
        width:4.1rem;height:1.33rem;
        position: absolute;left:3.33rem;top:39.75rem;
    }
    .headContent {
        display: flex;
        justify-content: center;
        font-size: 1.5rem;
        color: #fff;
        height: 2rem;
        background: linear-gradient(to right, #f00c0c, #ebba92);

        span {
            align-self: center;
            height: 50px;
            width: auto;
            line-height: 50px;
            font-size: .47rem;
            font-weight: 500;
        }

        .logo {
            align-self: center;
            width: 1.69rem;
            height: auto;
            margin-right: .55rem;
        }
    }
    .bindButton {
        color     : #fff;
        font-size : 14px;
        margin    : 0 5px 5px 5px;
        background: linear-gradient(to right, #f00c0c, #ebba92);
    }
}