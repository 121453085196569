.baodanpdf {
    // img {
    //     width: 100%;
    //     height: auto;
    //     overflow: auto;
    // }
    .yema{
        position: fixed;left: 20px;top:20px;z-index: 99999999;
    }
    // .pageclass1{width:100% !important;}
    // canvas,div{
    //     width: 100% !important;
    //     height:auto !important;
    // }
    .react-pdf__Page__annotations{
        display: none;
    }
}