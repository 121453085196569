.xwbuyingcenter {

    .headContent {
        display: flex;
        justify-content: center;
        font-size: .48rem;
        color: #fff;
        height: 1.28rem;
        background: linear-gradient(to right, #ebba92, #f00c0c);

        span {
            align-self: center;
            height: 50px;
            width: 150px;
            line-height: 50px;
        }

        .logo {
            align-self: center;
            width: .95rem;
            height: auto;
        }
    }

    .dianbeijing {
        position: relative;
        .dbg {
            width: 100%;
            height: auto;
        }
        .taobaozhognchou{
            display: inline-block;
            width: 2.15rem;
            height:.5rem;
            position: absolute;
            right:.86rem;
            bottom: .3rem;
        }
    }

    .dianpulianjie {
        display: flex;
        justify-content: center;
        align-items: center;

        .bot-right {
            border-right: .03rem solid #e6e6e6
        }

        div {
            width: 50%;
            text-align: center;
            margin: .55rem 0 .52rem 0;

            span {
                display: block;
                width: 100%;

                .tianmao {
                    width: 1.65rem;
                    height: auto;
                    margin: .2rem 0 .65rem 0
                }

                .jingdong {
                    width: 2.23rem;
                    height: auto;
                    margin: .2rem 0 .65rem 0
                }

                .hyj1 {
                    width: 4.08rem;
                }

                button {
                    width: 3.15rem;
                    height: 1.08rem;
                    border-radius: .2rem;
                    border: none;
                    background: linear-gradient(to right, #feb149, #f256a0);
                    font-size: .48rem;
                    color: #fefffd;
                    margin-bottom: .3rem;

                }
            }
        }
    }

    .bgbg {
        height: .4rem;
        background: #ededed;
    }

    .chanpinliebiao {
        padding-bottom: 3.6rem;

        .liebiaoxiang {
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            /*外层元素属性设置为space-between*/
            align-items: center;
            padding: .75rem .6rem .6rem .8rem;

            .cptuwen {
                display: flex;
                justify-content: left;
                align-items: left;

                span {
                    display: inline-block;
                    

                    em {
                        color: #010101;
                        font-size: .46rem;
                        display: block;
                        padding: .5rem 0 0 0;
                        text-align: left;
                    }

                    img {
                        width: 3.01rem;
                        margin-left: .35rem
                    }

                    i {
                        font-size: .24rem;
                    }

                    .price1 {
                        color: #ce7553
                    }
                }
            }

            .chanpinicon {
                width: 3.31rem;
                height: auto;
                vertical-align: middle;
            }

            i {
                display: inline-block;
            }


            button {
                width: 3.15rem;
                height: 1.08rem;
                border-radius: .2rem;
                border: none;
                background: linear-gradient(to right, #feb149, #f256a0);
                font-size: .48rem;
                color: #fefffd;
                margin-bottom: .3rem;
            }
        }

        .xxx {
            height: .03rem;
            background: #e6e6e6;
            margin: 0 .66rem 0 1.88rem;
        }
    }















}